import React from "react";
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    NumberField,
    Pagination,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    ReferenceField
} from "react-admin";
import {
    approvalStageOptions,
    approvalStatusOptions,
    categoryOptions,
    tierOptions
} from "./config";
import { exporter } from "./Exporter";
import Actions from "./Actions";

const Filters = props => (
    <Filter {...props}>
        <SelectInput
            source="ApprovalStatus"
            choices={approvalStatusOptions}
        />
        <SelectInput source="Category" choices={categoryOptions} />
        <SelectInput
            source="ApprovalStage"
            choices={approvalStageOptions}
        />
        <SelectInput
            source="TierName"
            choices={tierOptions}
            alwaysOn
        />
        <TextInput label="Agent Code" source="AgentCode" />
        <TextInput label="User Id" source="UserId" />
        <ReferenceInput
            source="Country"
            reference="countries"
            sort={{ field: "Name", order: "ASC" }}
        >
            <SelectInput optionText="Name" />
        </ReferenceInput>
        {/* <DateInput
            source="UTCCheckedFrom"
            label="Checked Date From"
            options={{ format: "MM-DD-YYYY" }}
        />
        <DateInput
            source="UTCCheckedTo"
            label="Checked Date To"
            options={{ format: "MM-DD-YYYY" }}
        />
        <DateInput
            source="UTCPreApprovedFrom"
            label="Preapproved Date From"
            options={{ format: "MM-DD-YYYY" }}
        />
        <DateInput
            source="UTCPreApprovedTo"
            label="Preapproved Date To"
            options={{ format: "MM-DD-YYYY" }}
        /> */}
        <DateInput
            source="UTCApproveFrom"
            label="Approved Date From"
            options={{ format: "MM-DD-YYYY" }}
        />
        <DateInput
            source="UTCApproveTo"
            label="Approved Date To"
            options={{ format: "MM-DD-YYYY" }}
        />
        {/* <DateInput
            source="UTCRejectedFrom"
            label="Rejected Date From"
            options={{ format: "MM-DD-YYYY" }}
        />
        <DateInput
            source="UTCRejectedTo"
            label="Rejected Date To"
            options={{ format: "MM-DD-YYYY" }}
        /> */}
        {/* <DateInput
            source="UTCCreatedFrom"
            label="Created Date From"
            options={{ format: "MM-DD-YYYY" }}
        />
        <DateInput
            source="UTCCreatedTo"
            label="Created Date To"
            options={{ format: "MM-DD-YYYY" }}
        /> */}
    </Filter>
);

export default ({ permissions, ...props }) => (
    <List
        {...props}
        actions={<Actions exporter={exporter} />}
        exporter={false}
        filters={<Filters />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
        sort={{
            field: "UTCApproved",
            order: "DESC"
        }}
    >
        <Datagrid rowClick="show">
            <DateField source="UTCApproved" label="Date" showTime />
            <TextField source="AgentCode" label="Agent Code" sortable={false} />
            <TextField
                source="agent.Name"
                label="Agent Name"
                sortable={false}
            />
            <TextField
                source="agent.Country"
                label="Agent Country"
                sortable={false}
            />
            <TextField source="UserId" label="User ID" sortable={false} />
            <TextField source="TierName" sortable={false} />
            <ReferenceField
                source="Country"
                reference="countries"
                linkType={false}
                sortable={false}
                label="Reward Country"
            >
                <TextField source="Name" />
            </ReferenceField>
            <TextField source="Name" sortable={false} />
            <TextField source="RewardID" label="Order No" sortable={false} />
            <TextField source="VendorRewardId" label="Vendor Id" sortable={false} />
            {/* <NumberField source="Value" sortable={false} /> */}
            {/* <NumberField source="BonusValue" label="Bonus" sortable={false} /> */}
            <NumberField
                source="TotalValue"
                label="Total Value"
                sortable={false}
            />
            {/* <NumberField source="Points" sortable={false} /> */}
            {/* <NumberField source="PointsDiscount" sortable={false} /> */}
            <NumberField
                source="TotalPoints"
                label="Total Points"
                sortable={false}
            />
            {/* <TextField source="CheckedBy" label="Checked By" sortable={false} /> */}
            {/* <TextField
                source="ApprovalStatus"
                label="Status"
                sortable={false}
            />
            <TextField source="ApprovalStage" label="Stage" sortable={false} /> */}
            <DateField source="VendorRejectionDate" label="Failed Date" showTime />
            <TextField source="VendorRejectionDescription" label="Failed Note" sortable={false} />
        </Datagrid>
    </List>
);
