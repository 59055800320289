import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    EmailField,
    DateField,
    NumberField,
    ReferenceManyField,
    Datagrid,
    Pagination,
    BooleanField
} from "react-admin";

export default props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Summary">
                <TextField source="AgentCode" label="Agent Code" />
                <TextField source="agent.Name" label="Agent Name" />
                <TextField source="agent.City" label="Agent City" />
                <TextField source="agent.Country" label="Agent Country" />
                <BooleanField source="IsBlacklisted" label="Blacklist Redemption" />
                <TextField source="UserId" label="User ID" />
                <TextField source="Name" />
                <TextField source="OpsigoUserId" label="Opsigo User ID" />
                <EmailField source="Email" />
                <TextField source="Phone" />
                <TextField source="TierName" label="Tier Name" />
                <NumberField source="Point" label="Points" />
                <TextField source="Address" label="Address"/>
                <TextField source="City" />
                <TextField source="Status" />
                <TextField source="ClaimBankName" label="Claim Bank Name"/>
                <TextField source="ClaimBankAccount" label="Claim Bank Account"/>
                <TextField source="ClaimBankHolder" label="Claim Bank Holder"/>
                <TextField source="ClaimBankAddress" label="Claim Bank Address"/>
                <TextField source="ClaimSwift" label="Claim Swift Code"/>
                <TextField source="ClaimBusinessReg" label="Claim Business Reg"/>
                <TextField source="ClaimPassport" label="Claim Passport"/>
                <TextField source="ClaimTokopediaEmail" label="Claim Tokopedia Email"/>
                <TextField source="ClaimOvoPhone" label="Claim Ovo Phone"/>
                <TextField source="ClaimMapId" label="Claim Map Id" />
                <TextField source="ClaimMapPhone" label="Claim Map Phone" />
                <TextField source="ClaimMapName" label="Claim Map Name" />
            </Tab>
            <Tab label="Transactions" path="transactions">
                <ReferenceManyField
                    label="Transactions"
                    reference="transactions"
                    target="MemberID"
                    source="id"
                    pagination={<Pagination />}
                >
                    <Datagrid>
                        <TextField source="PNRNumber" label="PNR Number" />
                        <DateField source="BookingDate" label="Booking Date" />
                        <TextField source="HotelName" label="Hotel" />
                        <DateField source="CheckIn" label="Check-In" />
                        <DateField source="CheckOut" label="Check-Out" />
                        <NumberField source="Room" />
                        <NumberField source="Night" />
                        <NumberField source="Quantity" />
                        <TextField source="City" />
                        <TextField source="Country" />
                        <TextField source="PaxName" label="Pax Name" />
                        <TextField
                            source="CustomerName"
                            label="Customer Name"
                        />
                        <TextField
                            source="CustomerCity"
                            label="Customer City"
                        />
                        <TextField
                            source="CustomerCountry"
                            label="Customer Country"
                        />
                        <NumberField source="Points" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Account Statement" path="account-statements">
                <ReferenceManyField
                    label="Account Statement"
                    reference="account-statements"
                    target="MemberID"
                    source="id"
                    pagination={
                        <Pagination rowsPerPageOptions={[25, 50, 100, 200]} />
                    }
                    perPage={25}
                    sort={{ field: "Date", order: "DESC" }}
                >
                    <Datagrid>
                        <DateField source="Date" />
                        <TextField source="Description" sortable={false} />
                        <NumberField
                            source="PreviousBalance"
                            sortable={false}
                        />
                        <TextField source="Amount" sortable={false} />
                        <NumberField source="Balance" sortable={false} />
                        <NumberField source="Account" sortable={false} />
                        <TextField source="Source" sortable={false} />
                        <DateField
                            source="UTCCreated"
                            label="Date Created"
                            sortable={false}
                        />
                        <TextField
                            source="CreatedBy"
                            label="Created By"
                            sortable={false}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Point History">
                <ReferenceManyField
                    label="Point History"
                    reference="point/member"
                    target="MemberID"
                    source="id"
                    pagination={
                        <Pagination rowsPerPageOptions={[25, 50, 100, 200]} />
                    }
                    perPage={25}
                    sort={{ field: "UTCExpired", order: "DESC" }}
                >
                    <Datagrid>
                        <DateField source="UTCCreated" label="Date Created" />
                        <TextField source="Description" label="Description" sortable={false} />
                        <NumberField
                            source="Points"
                            label="Points"
                            sortable={false}
                        />
                        <DateField
                            source="UTCExpired"
                            label="Expired"
                            sortable={false}
                        />
                        <TextField
                            source="CreatedBy"
                            label="Created By"
                            sortable={false}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
