import React from "react";
import Card from "@material-ui/core/Card";
import { Title } from "react-admin";
import { ChangeAgentRequests } from "./ChangeAgentRequests";
import { NumberOfAgents } from "./NumberOfAgents";
import { NumberOfMembers } from "./NumberOfMembers";
import { PendingMembers } from "./PendingMembers";
import { PendingRedemptions } from "./PendingRedemptions";
import { UnclaimedPoints } from "./UnclaimedPoints";
import { ClaimGraph } from "./claimGraph"
import { MemberGraph } from "./MemberCountry"
import { RegisGraph } from "./RegisGraph";
import { Points } from './Points'

const styles = {
    flex: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gridTemplateColumns: "repeat(3, auto)",
        minWidth: "100%",
        fontFamily: "Roboto",
    },
    flex2: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gridTemplateColumns: "repeat(2, auto)",
        flexDirection: 'row',
        minWidth: "100%",
        fontFamily: "Crimson Text"
    },
    card: {
        minWidth: "100%"
    }
};

export default () => (
    <Card style={styles.card}>
        <Title title="Welcome to the administration" />
        <div style={styles.flex}>
            <ChangeAgentRequests />
            <PendingRedemptions />
            <PendingMembers />
            <NumberOfAgents />
            <NumberOfMembers />
            <UnclaimedPoints />
            <Points/>
            <RegisGraph/>
            <ClaimGraph/>
            <MemberGraph/>
        </div>
    </Card>
);